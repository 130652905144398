import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Edwin | Developer',
  lang: 'en',
  description: 'Welcome to my portfolio',
};

// HERO DATA
export const heroData = {
  title: ' ',
  name: 'Edwin Ruvalcaba',
  subtitle: 'Web Developer',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile_pic.png',
  paragraphOne:
    'I am a self-taught web developer based in San Diego. I have a passion for all things JavaScript, and specialize in creating beautiful and responsive web applications that people love to use.',
  paragraphTwo:
    'The technologies I am currently focused on are React, Node.js, Express, and PostgreSQL.',
  paragraphThree: 'When not coding I enjoy running, surfing, cooking, and playing video games!',
  resume: 'https://drive.google.com/file/d/1Jh3f6ghhe209LeD8IrW7jJHvTMgayJ1k/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1scr.png',
    title: 'Cool Cats & Kittens',
    info:
      'A React app to search through your cool cat friends. Inspired by the Netflix documentary "Tiger King".',
    info2: 'Technologies: React, Redux, RESTful API',
    url: 'https://edwinruvalcaba.github.io/cat-friends/',
    repo: 'https://github.com/edwinruvalcaba/cat-friends', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2scr.png',
    title: 'Face Detect App',
    info: 'Create an account and detect the faces in your submitted images using AI.',
    info2: 'Technologies: React, Node.js, Express, PostgreSQL, and Clarifai API',
    url: 'http://face-detect-smart-brain.herokuapp.com',
    repo: 'https://github.com/edwinruvalcaba/face-recognition', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3scr.png',
    title: 'Paint',
    info: "A fun drawing app similar to MS Paint for when you're feeling artsy.",
    info2: 'Technologies: JavaScript, HTML Canvas, CSS',
    url: 'https://edwinruvalcaba.github.io/paint-clone/',
    repo: 'https://github.com/edwinruvalcaba/paint-clone', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Get in touch!',
  btn: '',
  email: 'edwinruvalcaba@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/edwin-ruvalcaba',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/edwinruvalcaba',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
